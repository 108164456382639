// Here you can add other styles
$table-row-even: #f9f9f9; // 设置偶数行的背景颜色
$table-row-odd: #ffffff; // 设置奇数行的背景颜色

.table-row-even {
  background-color: $table-row-even;
}

.table-row-odd {
  background-color: $table-row-odd;
}

.custom-checkbox {
  // margin: -10px 0;
  line-height: 1.2;
}

.arrow-right {
  width: 10;
  height: 10;
  border-top: 10px solid transparent;   /* 上边框 */
  border-bottom: 10px solid transparent;   /* 下边框 */
  border-left: 20px solid #007bff;   /* 左边框，设置颜色为蓝色 */
  display: inline-block;
}
.arrow-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;   /* 左边框 */
  border-right: 10px solid transparent;   /* 右边框 */
  border-top: 20px solid #007bff;   /* 上边框，设置颜色为蓝色 */
  display: inline-block;
}